import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import FunfactOne from "@/components/funfact-one";
import ServiceTwo from "@/components/service-two";
import AboutTwo from "@/components/about-two";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import SliderOne from "@/components/slider-one";
import SliderTwo from "@/components/slider-two";
import MessengerCustomerChat from 'react-messenger-customer-chat';
const HomeOne = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="ЭХЛЭЛ | САНХҮҮГИЙН ТООЦООЛОХ ГРУПП ХХК">
          <HeaderOne />
          <SliderOne />
          <AboutTwo />
          <ServiceTwo />
          <SliderTwo />
          <FunfactOne />
          <Footer />
          <MessengerCustomerChat pageId="102057705836876" appId="1447283252440728"/>
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default HomeOne;
